.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  padding: 10px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.App-logo {
  height: 70px;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

/* Navigation links on larger screens */
.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;  /* Adds a gap between each item in the nav */
  list-style: none;
}

.nav-links ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;  /* Adds a gap between each item in the ul */
}

.nav-links ul li {
  display: inline-block;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger icon on small screens */
  }

  .nav-links {
    display: none; /* Hide nav links by default on small screens */
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #000000;
  }

  .nav-links.open {
    display: flex; /* Show the nav links when menu is open */
  }

  .nav-links ul {
    flex-direction: column; /* Stack menu items vertically on small screens */
    gap: 10px; /* Adds space between vertical items */
    width: 100%;
    text-align: center;
  }

  .nav-links ul li {
    margin: 10px 0;
  }
}

.iframe {
  margin-top: 40px;
}